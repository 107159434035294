var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "c-card",
    {
      staticClass: "cardClassDetailForm",
      staticStyle: { width: "1000px", "max-width": "1000px !important" },
      attrs: { title: "LBLDETAIL" },
    },
    [
      _c(
        "template",
        { slot: "card-button" },
        [
          _c(
            "q-btn-group",
            { attrs: { outline: "" } },
            [
              _vm.editable
                ? _c("c-btn", {
                    attrs: { label: "LBLAPPLY", icon: "check" },
                    on: { btnClicked: _vm.accept },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "card-detail" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("c-text", {
              attrs: { disabled: true, label: "총점수 (A x B)", name: "total" },
              model: {
                value: _vm.total,
                callback: function ($$v) {
                  _vm.total = $$v
                },
                expression: "total",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("c-table", {
              ref: "table1",
              attrs: {
                columns: _vm.grid1.columns,
                data: _vm.grid1.data,
                gridHeight: _vm.gridHeight,
                isTitle: false,
                hideBottom: true,
                isExcelDown: false,
                filtering: false,
                isFullScreen: false,
                columnSetting: false,
              },
              on: { rowClick: _vm.rowClick1 },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("c-table", {
              ref: "table2",
              attrs: {
                columns: _vm.grid2.columns,
                data: _vm.grid2.data,
                gridHeight: _vm.gridHeight,
                isTitle: false,
                hideBottom: true,
                isExcelDown: false,
                filtering: false,
                isFullScreen: false,
                columnSetting: false,
              },
              on: { rowClick: _vm.rowClick2 },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }